import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useLazyQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { LoaderHolder } from 'common'
import { ValidatorQueries } from 'common/types'
import { DEFAULT_SORT, MIN_ROWS_PER_PAGE_DEFAULT } from 'constants/params'
import { VALIDATOR_QUERIES } from 'graphql/queries/validatorQueries'

import Table from './table'

type Props = {
  validatorId: string
  onCopyAddress?: (field: string, text: string) => void
}

export default function ValidatorQueriesTable({
  validatorId,
  onCopyAddress,
}: Props) {
  const [page, setPage] = useState(0)

  const [loadNodes, { data: validatorQueriesData, loading, refetch }] =
    useLazyQuery(VALIDATOR_QUERIES)

  const fetchValidatorData = useCallback(() => {
    loadNodes({
      variables: {
        page: 1,
        take: MIN_ROWS_PER_PAGE_DEFAULT,
        validatorId,
        order: DEFAULT_SORT,
      },
    })
  }, [loadNodes, validatorId])

  useEffect(() => {
    if (validatorId) {
      fetchValidatorData()
    }
  }, [fetchValidatorData, validatorId])

  const validatorQueries: ValidatorQueries[] = useMemo(
    () => validatorQueriesData?.validatorQueries?.data || [],
    [validatorQueriesData],
  )
  const validatorQueriesMeta = useMemo(
    () => validatorQueriesData?.validatorQueries?.meta || [],
    [validatorQueriesData],
  )

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      refetch({ page: page + 1 })
      setPage(page)
    },
    [refetch],
  )

  if (loading)
    return (
      <LoaderHolder sx={{ height: '70px' }}>
        <CircularProgress />
      </LoaderHolder>
    )

  return (
    <Table
      count={validatorQueriesMeta?.itemCount ?? 0}
      data={validatorQueries}
      page={page}
      onCopyAddress={onCopyAddress}
      onPageChange={handlePageChange}
    />
  )
}
