import { UptimeChartKind } from 'common/blocks/uptimeStatsChart'
import { Point } from 'common/types'
import { commonChartOptions } from 'constants/chartOptions'
import { DateTime } from 'luxon'

import { getFormattedNumber } from './Number'

export const createUptimeStatsChartOptions = (
  xTicks?: Array<Date>,
  startHAxis?: string,
) => {
  const startHAxisDate = startHAxis ? new Date(startHAxis) : null

  return {
    ...commonChartOptions,
    colors: ['#4D75B8', '#26B568'],
    chartArea: { left: 40, right: 7, width: '100%', height: 260 },
    hAxis: {
      ...commonChartOptions?.hAxis,
      ticks: xTicks || [],
      ...(startHAxisDate && {
        viewWindow: { min: startHAxisDate },
        baseline: startHAxisDate,
      }),
    },
  }
}

export const generateTooltipBlock = (point: Point) => {
  return `
		<div style="background-color: ${'#fff'}; padding: 10px 5px; margin: 0; border-radius: 8px; min-width: 100px" >
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 0 5px 5px; margin: 0;">${DateTime.fromISO(
    point?.day,
  ).toFormat('LLL dd')}</p>
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 5px; margin: 0;">${getFormattedNumber(
    point.value,
  )}</p>
		</div>
		`
}

export const generatePercentageTooltipBlock = (point: Point) => {
  return `
		<div style="background-color: ${'#fff'}; padding: 10px 5px; margin: 0; border-radius: 8px; min-width: 100px" >
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 0 5px 5px; margin: 0;">${DateTime.fromISO(
    point?.day,
  ).toFormat('LLL dd')}</p>
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 5px; margin: 0;">${point?.value?.toFixed()}%</p>
		</div>
		`
}

export function generateUptimeStatsChartData(
  chartKind: UptimeChartKind,
  amountPoints: Point[],
  percentagePoints: Point[],
) {
  const chartPoints =
    chartKind === UptimeChartKind.AMOUNT ? amountPoints : percentagePoints

  if (!chartPoints?.length) return []

  return [
    [
      'Date',
      chartKind === UptimeChartKind.AMOUNT
        ? 'Amount of below uptime nodes'
        : 'Percent of such nodes to all active nodes',
      { role: 'tooltip', type: 'string', p: { html: true } },
    ],
    ...chartPoints.map(point => {
      return [
        new Date(point?.day),
        point?.value,
        chartKind === UptimeChartKind.AMOUNT
          ? generateTooltipBlock(point)
          : generatePercentageTooltipBlock(point),
      ]
    }),
  ]
}
