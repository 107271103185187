import { Point } from 'common/types'
import { commonChartOptions } from 'constants/chartOptions'
import { DateTime } from 'luxon'
import { getFormattedNumber } from 'utils/Number'

export const createPaidRewardsChartOptions = (startHAxis?: string) => {
  const startHAxisDate = startHAxis ? new Date(startHAxis) : null

  return {
    ...commonChartOptions,
    chartArea: { left: 50, right: 7, width: '100%', height: 250 },
    hAxis: {
      ...commonChartOptions?.hAxis,
      ...(startHAxisDate && {
        viewWindow: { min: startHAxisDate },
        baseline: startHAxisDate,
      }),
    },
    colors: ['#4D75B8'],
  }
}

export const generateTooltipBlock = (point: Point) => {
  return `
		<div style="background-color: ${'#fff'}; padding: 10px 5px; margin: 0; border-radius: 8px; min-width: 100px" >
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 0 5px 5px; margin: 0;">${DateTime.fromISO(
    point?.day,
  ).toFormat('LLL dd')}</p>
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 5px; margin: 0;">${getFormattedNumber(
    point.value,
  )} MNW</p>
		</div>
		`
}

export function generatePaidRewardsChartData(rewardsPaidPoints?: Point[]) {
  if (!rewardsPaidPoints?.length) return []

  return [
    [
      'Date',
      'Paid rewards',
      { role: 'tooltip', type: 'string', p: { html: true } },
    ],
    ...rewardsPaidPoints.map(point => [
      new Date(point?.day),
      point?.value,
      generateTooltipBlock(point),
    ]),
  ]
}
