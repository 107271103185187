import React, { useCallback, useMemo, useState } from 'react'
import { Chart } from 'react-google-charts'

import { useQuery } from '@apollo/client'
import {
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material'
import { Box } from '@mui/system'
import {
  EmptyChartPlaceholder,
  ErrorChartPlaceholder,
  LoaderHolder,
} from 'common'
import DateRange from 'common/ui/dateRange'
import { SYSTEM_DAILY_STATS_GRAPH_DATA } from 'graphql/systemDailyStats/queries'
import { useDateRange } from 'hooks'
import { getXTicks } from 'utils/Charts'
import {
  createUptimeStatsChartOptions,
  generateUptimeStatsChartData,
} from 'utils/UptimeStatsChart'

export enum UptimeChartKind {
  PERCENTAGE = 'percentage',
  AMOUNT = 'amount',
}

function UptimeStatsChart() {
  const [chartKind, setChartKind] = useState(UptimeChartKind.AMOUNT)
  const { range, setFrom, setTo, formattedFrom, formattedTo } = useDateRange()

  const { data, loading, error } = useQuery(SYSTEM_DAILY_STATS_GRAPH_DATA, {
    variables: {
      from: formattedFrom,
      to: formattedTo,
    },
  })

  const belowUptimeAmount = useMemo(
    () => data?.systemDailyStatsGraphData?.belowUptimeAmount || [],
    [data],
  )

  const belowUptimeDiffAllPercentage = useMemo(
    () => data?.systemDailyStatsGraphData?.belowUptimeDiffAllPercentage || [],
    [data],
  )

  const xTicks = useMemo(
    () => getXTicks(belowUptimeAmount),
    [belowUptimeAmount],
  )

  const startHAxis = useMemo(() => {
    if (chartKind === UptimeChartKind.AMOUNT) {
      return data?.systemDailyStatsGraphData?.belowUptimeAmount[0]?.day
    }
    return data?.systemDailyStatsGraphData?.belowUptimeDiffAllPercentage[0]?.day
  }, [chartKind, data])

  const chartOptions = createUptimeStatsChartOptions(xTicks, startHAxis)

  const statsChartData = useMemo(
    () =>
      generateUptimeStatsChartData(
        chartKind,
        belowUptimeAmount,
        belowUptimeDiffAllPercentage,
      ),
    [belowUptimeAmount, belowUptimeDiffAllPercentage, chartKind],
  )

  const chartColors = useMemo(() => {
    if (chartKind === UptimeChartKind.AMOUNT) {
      return ['#4D75B8']
    }

    return ['#26B568']
  }, [chartKind])

  const handleChangeChartKind = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setChartKind((event.target as HTMLInputElement).value as UptimeChartKind)
    },
    [],
  )

  const noChartData = useMemo(() => {
    if (chartKind === UptimeChartKind.AMOUNT) {
      return belowUptimeAmount?.length < 2
    }
    return belowUptimeDiffAllPercentage?.length < 2
  }, [belowUptimeAmount, belowUptimeDiffAllPercentage, chartKind])

  if (loading)
    return (
      <LoaderHolder>
        <CircularProgress />
      </LoaderHolder>
    )

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mt={1}
        width="100%"
      >
        <RadioGroup row value={chartKind} onChange={handleChangeChartKind}>
          <FormControlLabel
            control={<Radio size="small" />}
            label={
              <Tooltip title={'Amount of below uptime nodes'}>
                <span>Nodes</span>
              </Tooltip>
            }
            value={UptimeChartKind.AMOUNT}
          />
          <FormControlLabel
            control={<Radio size="small" />}
            label={
              <Tooltip title={'Percent of such nodes to all active nodes'}>
                <span>Percent</span>
              </Tooltip>
            }
            value={UptimeChartKind.PERCENTAGE}
          />
        </RadioGroup>
        <Box display="flex">
          <DateRange range={range} onChangeFrom={setFrom} onChangeTo={setTo} />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="end"
      >
        {error && <ErrorChartPlaceholder />}
        {!error && noChartData && <EmptyChartPlaceholder />}
        {!error && !noChartData && (
          <Chart
            chartType="AreaChart"
            data={statsChartData}
            height="100%"
            options={{ ...chartOptions, colors: chartColors }}
            width="100%"
          />
        )}
      </Box>
    </>
  )
}

export default UptimeStatsChart
