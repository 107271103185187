export const propertiesMetadata = {
  // 2
  bridgedTokenAmount: {
    title: 'MNW Bridged',
    hint: 'Number of all bridged tokens',
  },
  // 3
  bridgedTokenDailyDiff: {
    title: 'Change',
    hint: 'Difference of bridged tokens from the previous day (negative dynamic to highlight in red, positive in green)',
  },
  // 4
  bridgetTokenCirculationPercentage: {
    title: 'Circul. in %',
    hint: 'Percentage tokens bridged of circulation supply',
  },
  // 5
  stackedTokenAmount: {
    title: 'MNW Staked',
    hint: 'The number of all tokens staked',
  },
  // 6
  stackedTokenAmountCirculationPercentage: {
    title: 'Circul. in %',
    hint: 'Percentage tokens staked of circulating suppl',
  },
  // 7
  stackedNodesCount: {
    title: 'Stake',
    hint: 'The number of all nodes staked',
  },
  // 8
  unstakedNodesCount: {
    title: 'Unstake',
    hint: 'The number of all nodes unstacked',
  },
  // 9
  stakedNodesDailyDiff: {
    title: 'Nodes ++',
    hint: 'Nodes added/removed on last day',
  },
  // 10
  totalActiveNodesCount: {
    title: 'Running nodes',
    hint: 'The number of all running nodes',
  },
  // 11
  avgNodesCountPerValidator: {
    title: 'Avg nodes per validator',
    hint: 'Average nodes per validator',
  },
  // 12
  totalRewardsPaid: {
    title: 'Total Rewards paid',
    hint: 'Total Rewards paid to date',
  },
  // 13
  registeredValidatorsCount: {
    title: 'Register validators',
    hint: 'How many validators registered',
  },
  // 14
  closedValidatorsCount: {
    title: 'Close validator',
    hint: 'Validators added/removed or Registered/Closed on last day',
  },
  // 15
  validatorsDailyDiff: {
    title: 'Validators',
    hint: '+ - Total validators',
  },
  // 16
  totalActiveValidatorsCount: {
    title: 'Running validators',
    hint: 'How many active validators',
  },
  // 19
  tokenPrice: {
    title: 'MNW price',
    hint: 'The price of 1 MNW token',
  },
  // 21
  totalLockedAmount: {
    title: 'TVL',
    hint: 'Used to measure the total value of digital assets that are locked or staked',
  },

  // 22
  cliAppVersion: {
    title: 'CLI version',
    hint: 'Version of CLI',
  },

  // 23
  validationsCap: {
    title: 'Validations Cap',
    hint: 'Daily validations count',
  },
  // 23
  validationsAmount: {
    title: 'Validations Amount',
    hint: 'Amount of fulfilled validation per day',
  },
  // 24
  belowUptimeAmount: {
    title: 'Below Uptime Amount',
    hint: 'Amount of below uptime nodes',
  },
  // 24
  belowUptimeDiffAllPercentage: {
    title: 'Below Uptime Diff All Percentage',
    hint: 'Percent of such nodes to all active nodes',
  },

  // 25
  earlyUnstakeNodeAmount: {
    title: 'Nodes unstaked',
    hint: 'Nodes that were unstaked ahead of time ',
  },

  // 26
  earlyUnstakeValidatorAmount: {
    title: 'Validators unstaked',
    hint: 'Validators that were unstaked ahead of time',
  },
}
