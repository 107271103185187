import { gql } from '@apollo/client'

export const VALIDATOR_QUERY_FIELDS = gql`
  fragment ValidatorQueryFields on ValidatorQuery {
    id
    addedAt
    failedAt
    failureReason

    node {
      address
      id
    }
    processedAt

    queryId
    query {
      queryUrl
    }

    validatorId
  }
`
