import React, { MouseEvent } from 'react'

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { ValidatorQueries } from 'common/types'
import { MIN_ROWS_PER_PAGE_DEFAULT } from 'constants/params'

import map from 'lodash/map'

import ValidatorQueryRow from './validatorQueryRow'

type Props = {
  count: number
  data?: ValidatorQueries[]
  page: number
  onCopyAddress?: (field: string, text: string) => void
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void
}

export default function BasicTable({
  data = [],
  count,
  onCopyAddress,
  onPageChange,
  page,
}: Props) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: '12px',
      }}
    >
      <Table aria-label="Validators table" size="small" sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Query url</TableCell>
            <TableCell align="left">Node Address</TableCell>
            <TableCell align="left">Added at</TableCell>
            <TableCell align="left">Processed at</TableCell>
            <TableCell align="left">Failed At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data?.length ? (
            <TableRow>
              <TableCell align={'center'} colSpan={7}>
                The list is empty
              </TableCell>
            </TableRow>
          ) : (
            <>
              {map(data, (row: ValidatorQueries) => (
                <ValidatorQueryRow
                  key={row.id}
                  row={row}
                  onCopyAddress={() =>
                    onCopyAddress?.(
                      row?.node?.address,
                      'The node address was copied!',
                    )
                  }
                />
              ))}
              <TablePagination
                count={count}
                page={page}
                rowsPerPage={MIN_ROWS_PER_PAGE_DEFAULT}
                rowsPerPageOptions={[]}
                onPageChange={onPageChange}
              />
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
