import { gql } from '@apollo/client'

import { VALIDATOR_QUERY_FIELDS } from './fragments'

export const VALIDATOR_QUERIES = gql`
  query validatorQueries(
    $validatorId: ID!
    $filter: Object
    $search: String
    $page: Int
    $order: String
    $take: Int
  ) {
    validatorQueries(
      validatorId: $validatorId
      filter: $filter
      search: $search
      page: $page
      order: $order
      take: $take
    ) {
      data {
        ...ValidatorQueryFields
      }
      meta {
        page
        take
        itemCount
        pageCount
        hasPreviousPage
        hasNextPage
      }
    }
  }
  ${VALIDATOR_QUERY_FIELDS}
`
