import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { Phase } from 'common/types'
import { CURRENT_PHASE } from 'graphql/phase/queries/currentPhase'
import { PHASES } from 'graphql/phase/queries/phases'
import { getFormattedDate } from 'utils/Date'

import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

import NodesTable from './nodesTable'
import QuestsActivitiesTable from './questsActivitiesTable'
import ValidatorQueriesTable from './validatorQueriesTable'

type Props = {
  validatorId: string
  onCopyAddress?: (field: string, text: string) => void
}

function ValidatorTables({ validatorId, onCopyAddress }: Props) {
  const [selectedPhase, setSelectedPhase] = useState<Phase | null>(null)

  const { data: currentPhaseData } = useQuery(CURRENT_PHASE)

  const { data: phasesData } = useQuery(PHASES)

  const currentPhase: Phase = useMemo(
    () => currentPhaseData?.currentPhase,
    [currentPhaseData],
  )

  useEffect(() => {
    if (currentPhase?.id) {
      setSelectedPhase(currentPhase)
    }
  }, [currentPhase])

  const phases: Phase[] = useMemo(
    () => phasesData?.phases || [],
    [phasesData?.phases],
  )

  const phasesByIndex = useMemo(() => keyBy(phases, 'index'), [phases])

  const handleSelectPhase = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const { value } = event.target
      setSelectedPhase(phasesByIndex[value as number])
    },
    [phasesByIndex],
  )

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      <Box>
        <Typography sx={{ my: 2, fontWeight: 600 }}>Nodes</Typography>
        <NodesTable validatorId={validatorId} onCopyAddress={onCopyAddress} />
      </Box>
      <Box>
        <Typography sx={{ my: 2, fontWeight: 600 }}>Validations</Typography>
        <ValidatorQueriesTable
          validatorId={validatorId}
          onCopyAddress={onCopyAddress}
        />
      </Box>
      <Box>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          mb={1}
        >
          <Typography sx={{ fontWeight: 600 }}>Quests</Typography>
          <Box>
            <Select
              IconComponent={ExpandMoreIcon}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundImage: 'none',
                    height: '300px',
                  },
                },
              }}
              defaultValue={currentPhase?.index || ''}
              disableUnderline
              displayEmpty
              fullWidth
              label="Phase"
              labelId="select-phase"
              size="small"
              sx={{ borderRadius: '6px', mx: 0, mb: 1 }}
              value={selectedPhase?.index}
              variant="standard"
              onChange={handleSelectPhase}
            >
              <MenuItem disabled value={''}>
                <Typography component="span">
                  <em>Select phase</em>
                </Typography>
              </MenuItem>
              {map(phases, (phase: Phase) => (
                <MenuItem key={phase.id} value={phase.index}>
                  {`Phase ${phase.index}: ${getFormattedDate(
                    phase?.startDate,
                  )}-${getFormattedDate(phase?.endDate)} ${
                    phase.index === currentPhase?.index ? `(Current)` : ''
                  }`}
                </MenuItem>
              ))}
            </Select>
            {!currentPhase && (
              <Typography color="text.secondary" fontSize="12px">
                <em>*There is no active phase at the moment</em>
              </Typography>
            )}
          </Box>
        </Box>

        <QuestsActivitiesTable
          phase={selectedPhase}
          validatorId={validatorId}
        />
      </Box>
    </Box>
  )
}

export default ValidatorTables
