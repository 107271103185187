import React from 'react'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import { Box, IconButton, TableCell, TableRow, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import { ValidatorQueries } from 'common/types'
import { truncateAddress } from 'constants/truncateAddress'
import { getFormattedDateFromTimestamp } from 'utils/Date'

interface Props {
  row: ValidatorQueries
  onCopyAddress?: () => void
}

function ValidatorQueryRow({ row, onCopyAddress }: Props) {
  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell align="left">{row?.query?.queryUrl}</TableCell>
      <TableCell align="left">
        <Box alignItems="center" display="flex">
          {truncateAddress(row?.node?.address)}
          {row?.node?.address && (
            <Tooltip arrow placement="top" title="Copy address to clipboard">
              <IconButton
                color="primary"
                size="small"
                sx={{ ml: 1 }}
                onClick={() => onCopyAddress?.()}
              >
                <ContentCopyIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </TableCell>

      <TableCell align="left">
        {getFormattedDateFromTimestamp(row.addedAt)}
      </TableCell>
      <TableCell align="left">
        {getFormattedDateFromTimestamp(row.processedAt)}
      </TableCell>
      <TableCell align="left">
        <Box alignItems="center" display="flex" justifyContent="start">
          <Typography fontSize="inherit">
            {getFormattedDateFromTimestamp(row.failedAt)}
          </Typography>
          {row?.failureReason && (
            <Tooltip sx={{ mt: '5px' }} title={row?.failureReason}>
              <ReportProblemIcon
                color="error"
                sx={{ height: '18px', mr: 0.5 }}
              />
            </Tooltip>
          )}
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default ValidatorQueryRow
