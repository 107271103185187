import { gql } from '@apollo/client'

export const SYSTEM_DAILY_STATS = gql`
  query systemDailyStats(
    $filter: Object
    $page: Int
    $take: Int
    $order: String
    $from: DateTime
    $to: DateTime
  ) {
    systemDailyStats(
      filter: $filter
      page: $page
      take: $take
      order: $order
      from: $from
      to: $to
    ) {
      data {
        id
        day
        bridgedTokenAmount
        bridgedTokenDailyDiff
        bridgetTokenCirculationPercentage
        stackedTokenAmount
        stackedTokenAmountCirculationPercentage
        stackedNodesCount
        unstakedNodesCount
        stakedNodesDailyDiff
        totalActiveNodesCount
        avgNodesCountPerValidator
        totalRewardsPaid
        registeredValidatorsCount
        closedValidatorsCount
        validatorsDailyDiff
        totalActiveValidatorsCount
        tokenPrice
        totalLockedAmount
        cliAppVersion
        validationsCap
        validationsAmount
        belowUptimeAmount
        belowUptimeDiffAllPercentage
        earlyUnstakeNodeAmount
        earlyUnstakeValidatorAmount
      }
      meta {
        page
        take
        itemCount
        pageCount
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const SYSTEM_DAILY_STATS_GRAPH_DATA = gql`
  query systemDailyStatsGraphData($from: DateTime, $to: DateTime) {
    systemDailyStatsGraphData(from: $from, to: $to) {
      avgNodesCountPerValidator {
        value
        day
      }
      bridgedTokenAmount {
        value
        day
      }
      bridgedTokenDailyDiff {
        value
        day
      }
      bridgetTokenCirculationPercentage {
        value
        day
      }
      cliAppVersion {
        value
        day
      }
      closedValidatorsCount {
        value
        day
      }
      registeredValidatorsCount {
        value
        day
      }
      totalRewardsPaid {
        value
        day
      }
      stackedNodesCount {
        value
        day
      }
      stackedTokenAmount {
        value
        day
      }
      stackedTokenAmountCirculationPercentage {
        value
        day
      }
      stakedNodesDailyDiff {
        value
        day
      }
      tokenPrice {
        value
        day
      }
      totalActiveNodesCount {
        value
        day
      }
      totalActiveValidatorsCount {
        value
        day
      }
      totalLockedAmount {
        value
        day
      }
      unstakedNodesCount {
        value
        day
      }
      validatorsDailyDiff {
        value
        day
      }
      validationsCap {
        value
        day
      }
      validationsAmount {
        value
        day
      }
      belowUptimeAmount {
        value
        day
      }
      belowUptimeDiffAllPercentage {
        value
        day
      }
      earlyUnstakeNodeAmount {
        value
        day
      }
      earlyUnstakeValidatorAmount {
        value
        day
      }
    }
  }
`

export const SYSTEM_DAILY_STATS_DATASET = gql`
  query systemDailyStatsDataset {
    systemDailyStatsDataset {
      bridgedTokenAmount
      bridgedTokenDiff
      circulationSupply
      maxTrustMNWTokens
      stackedTokenAmount
      stakedTokenDiff
      totalActiveNodesCount
      totalActiveNodesDiff
      totalActiveValidatorsCount
      totalActiveValidatorsDiff
      totalValidatorsCount
      nodesWeeklyDiff
      nodesWeeklyDiffPercentage
      tokensWeeklyDiff
      tokensWeeklyDiffPercentage
      tokensDailyDiff
      tokensDailyDiffPercentage
      totalRewards
      nodeStakedByUserCount {
        key
        value
      }
      nodesCountByPeriod {
        count
        period
      }
      validatorsCountByPeriod {
        count
        period
      }
    }
  }
`
