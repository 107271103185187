import React, { useMemo } from 'react'
import { Chart } from 'react-google-charts'

import { useQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import {
  EmptyChartPlaceholder,
  ErrorChartPlaceholder,
  LoaderHolder,
} from 'common'
import DateRange from 'common/ui/dateRange'
import { SYSTEM_DAILY_STATS_GRAPH_DATA } from 'graphql/systemDailyStats/queries'
import { useDateRange } from 'hooks'
import {
  createPaidRewardsChartOptions,
  generatePaidRewardsChartData,
} from 'utils/PaidRewardsChart'

function RewardsPaidStatsChart() {
  const { range, setFrom, setTo, formattedFrom, formattedTo } = useDateRange()

  const { data, loading, error } = useQuery(SYSTEM_DAILY_STATS_GRAPH_DATA, {
    variables: {
      from: formattedFrom,
      to: formattedTo,
    },
  })

  const rewardsPaid = useMemo(
    () => data?.systemDailyStatsGraphData?.totalRewardsPaid || [],
    [data],
  )

  const startHAxis = useMemo(
    () => data?.systemDailyStatsGraphData?.totalRewardsPaid[0]?.day,
    [data],
  )

  const chartOptions = createPaidRewardsChartOptions(startHAxis)

  const statsChartData = useMemo(
    () => generatePaidRewardsChartData(rewardsPaid),
    [rewardsPaid],
  )

  const noChartData = useMemo(() => {
    return rewardsPaid?.length < 2
  }, [rewardsPaid])

  if (loading)
    return (
      <LoaderHolder>
        <CircularProgress />
      </LoaderHolder>
    )

  return (
    <>
      <Box alignItems="center" display="flex" justifyContent="end">
        <DateRange range={range} onChangeFrom={setFrom} onChangeTo={setTo} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="end"
      >
        {error && <ErrorChartPlaceholder />}
        {!error && noChartData && <EmptyChartPlaceholder />}
        {!error && !noChartData && (
          <Box height="300px" width="100%">
            <Chart
              chartType="AreaChart"
              data={statsChartData}
              height="100%"
              options={chartOptions}
              width="100%"
            />
          </Box>
        )}
      </Box>
    </>
  )
}

export default RewardsPaidStatsChart
